import {
	json,
	type HeadersFunction,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import {
	Form,
	Link,
	Links,
	Meta,
	NavLink,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useMatches,
	useSubmit,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { useRef } from 'react'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { EpicProgress } from './components/progress-bar.tsx'
import { useToast } from './components/toaster.tsx'
import { Button } from './components/ui/button.tsx'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from './components/ui/dropdown-menu.tsx'
import { Icon, href as iconsHref } from './components/ui/icon.tsx'
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from './components/ui/sheet.tsx'
import { EpicToaster } from './components/ui/sonner.tsx'
import { useTheme } from './routes/resources+/theme-switch.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getUserId, logout } from './utils/auth.server.ts'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import {
	cn,
	combineHeaders,
	getDomainUrl,
	getUserImgSrc,
} from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { getTheme, type Theme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { useOptionalUser, useUser } from './utils/user.ts'

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{
			rel: 'alternate icon',
			type: 'image/png',
			href: '/favicons/favicon-32x32.png',
		},
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'Tremolo' : 'Error | Tremolo' },
		{ name: 'description', content: `Your own captain's log` },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							name: true,
							username: true,
							image: { select: { id: true } },
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
	allowIndexing = true,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
	allowIndexing?: boolean
}) {
	return (
		<html
			lang="en"
			className={`${theme} h-full overflow-x-hidden antialiased selection:bg-primary selection:text-primary-foreground`}
		>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const theme = useTheme()
	const matches = useMatches()
	const mailListMatch = matches.find(
		(m) => m.id === 'routes/_marketing+/mailing-list',
	)
	const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'
	useToast(data.toast)

	return (
		<Document
			nonce={nonce}
			theme={theme}
			allowIndexing={allowIndexing}
			env={data.ENV}
		>
			<div
				className={cn(
					{ 'bg-primary-light': mailListMatch },
					'relative flex h-screen flex-col justify-between',
				)}
			>
				<header className="z-10 px-8 xl:px-24">
					<nav className="flex flex-wrap items-center justify-between gap-4 pt-12 sm:flex-nowrap md:gap-8">
						<NavBar mailListMatch={!!mailListMatch} />
					</nav>
					<p
						className={cn(
							{ hidden: mailListMatch },
							'ml-10 pt-1 text-xl font-light leading-none text-primary md:ml-16 md:text-3xl xl:ml-32 xl:pt-2 xl:text-3xl',
						)}
					>
						lessons your way
					</p>
				</header>

				<div className="relative flex-1">
					<Outlet />
				</div>
			</div>
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
		</Document>
	)
}

function NavBar({ mailListMatch }: { mailListMatch: boolean }) {
	const user = useOptionalUser()

	return (
		<>
			<Logo />
			{mailListMatch ? null : (
				<div className="flex items-center">
					{user ? (
						<UserDropdown />
					) : (
						<div>
							<ul className="hidden items-center text-lg text-muted-foreground lg:flex lg:gap-2">
								<li className="-mb-4 flex-shrink-0 rounded-full px-2 hover:bg-primary/15 has-[.active]:bg-primary/25 has-[.active]:text-primary-dark">
									<NavLink
										to="/our-mission"
										className={({ isActive }) => (isActive ? 'active' : '')}
									>
										Our Mission
									</NavLink>
								</li>
								{/* TODO: restore */}
								{/* <li className="-mb-4 flex-shrink-0 rounded-full px-2 hover:bg-primary/15 has-[.active]:bg-primary/25 has-[.active]:text-primary-dark">
						<NavLink
							to="/instructors"
							className={({ isActive }) => (isActive ? 'active' : '')}
						>
							Instructors
						</NavLink>
					</li> */}
								{/* TODO: restore */}
								{/* <li className="-mb-4 flex-shrink-0 rounded-full px-2 hover:bg-primary/15 has-[.active]:bg-primary/25 has-[.active]:text-primary-dark">
						<NavLink
							to="/teacher-resources"
							className={({ isActive }) => (isActive ? 'active' : '')}
						>
							Teacher Resources
						</NavLink>
					</li> */}
								<li className="-mb-4 flex-shrink-0 rounded-full px-2 hover:bg-primary/15 has-[.active]:bg-primary/25 has-[.active]:text-primary-dark">
									<NavLink
										to="/faq"
										className={({ isActive }) => (isActive ? 'active' : '')}
									>
										FAQ
									</NavLink>
								</li>
								<li className="-mb-3 flex-shrink-0">
									<Icon className="text-primary" name="bars" />
								</li>
								<li className="-mb-4 flex-shrink-0 rounded-full px-2 hover:bg-primary/15 has-[.active]:bg-primary/25 has-[.active]:text-primary-dark">
									<NavLink
										to="/login"
										className={({ isActive }) => (isActive ? 'active' : '')}
									>
										Login
									</NavLink>
								</li>
								<li className="-mb-4 flex-shrink-0">
									<Button
										asChild
										variant="secondary"
										size="wide"
										className="bg-secondary/50 px-1 py-0.5 text-lg font-semibold"
									>
										<Link to="/signup">Sign Up</Link>
									</Button>
								</li>
							</ul>
							<div className="-mr-4 lg:hidden">
								<Sheet>
									<SheetTrigger asChild>
										<Button
											variant="ghost"
											className="border-primary/50 focus-within:ring-primary hover:bg-primary/10"
										>
											<Icon
												name="bars"
												title="navigation"
												size="lg"
												className="text-primary"
											/>
										</Button>
									</SheetTrigger>
									<SheetContent>
										<SheetHeader className="text-left font-semibold">
											<SheetTitle>Tremolo</SheetTitle>
										</SheetHeader>
										<div className="mt-6 flex flex-col gap-6">
											<SheetClose asChild>
												<Link to="/" prefetch="intent">
													Home
												</Link>
											</SheetClose>
											<SheetClose asChild>
												<Link to="/our-mission" prefetch="intent">
													Our Mission
												</Link>
											</SheetClose>
											{/* TODO: restore */}
											{/* <SheetClose asChild>
									<Link to="/instructors" prefetch="intent">
										Instructors
									</Link>
								</SheetClose> */}
											{/* TODO: restore */}
											{/* <SheetClose asChild>
									<Link to="/teacher-resources" prefetch="intent">
										Teacher Resources
									</Link>
								</SheetClose> */}
											<SheetClose asChild>
												<Link to="/faq" prefetch="intent">
													FAQ
												</Link>
											</SheetClose>
											<SheetClose asChild>
												<Link to="/login" prefetch="intent">
													Log In
												</Link>
											</SheetClose>
											<SheetClose asChild>
												<Link to="/signup" prefetch="intent">
													Sign Up
												</Link>
											</SheetClose>
										</div>
									</SheetContent>
								</Sheet>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	)
}

function Logo() {
	return (
		<Link to="/" className="group grid">
			<span className="transition group-hover:-translate-x-1">
				<Icon
					name="logo"
					title="tremolo"
					className="h-11 w-32 text-muted-foreground md:h-8 md:w-48"
				/>
			</span>
		</Link>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button asChild variant="secondary">
					<Link
						to={`/users/${user.username}`}
						// this is for progressive enhancement
						onClick={(e) => e.preventDefault()}
						className="flex items-center gap-2"
					>
						<img
							className="h-8 w-8 rounded-full object-cover"
							alt={user.name ?? user.username}
							src={getUserImgSrc(user.image?.id)}
						/>
						<span className="text-body-sm font-bold">
							{user.name ?? user.username}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={8} align="start">
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}/notes`}>
							<Icon className="text-body-md" name="pencil-2">
								Notes
							</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={(event) => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon className="text-body-md" name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
